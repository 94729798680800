/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {

    // "keywords": [
  //   "konya yazılım firmaları",
  //   "pixel yazılım",
  //   "piksel yazılım",
  //   "piksel bilişim",
  //   "pdks yazılımları",
  //   "çelik üretim akış",
  //   "muhasebe yazılımları",
  //   "özel projeler",
  //   "start up",
  //   "parmak izi yüz tanıma",
  //   "netsis entegrasyon",
  //   "sipariş fatura e-fatura",
  //   "mobil uygulama",
  //   "veteriner yazılımı",
  //   "en iyi en güvenilir yazılım"
  // ],
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            image
          }
        }
      }
    `
  )

  const image = site.siteMetadata.image
  const keys =  site.siteMetadata.keywords
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keys,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `tr`,
  meta: [],
  description: `pixel konya yazılım firması pdks yazılımları mobil uygulama piksel`,
  //keywords:[`konya yazılım firmaları`, `piksel yazılım`,`pixel yazılım`]
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  //keywords: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}

export default SEO
